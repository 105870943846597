@import 'styles/_variables.css';
@import 'styles/_fonts.css';

*, ::after, ::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}


@media (max-width: 768px) {
  html {
    font-size: 60%;
  }
}

body {
  min-width: var(--wrapperWidthMin);
  margin: 0;
  padding: 0;
  color: var(--grey-darker);
  font-size: 1.6rem;
  font-family: 'Arimo','OpenSans', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

a {
  color: var(--secondary-color);
  text-decoration: none;
  font-family: 'ArimoBold','OpenSans', Arial, Helvetica, sans-serif;
  transition: all var(--transitionTime);
}

a:hover,
a.active {
  color: var(--primary-color-dark);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--black);
}

@media (max-width: 768px) {
  body h1, body .MuiTypography-h1 {
    font-size: 2.4rem;
  }
}

small {
  font-size: 80%;
}

legend {
  padding: 0;
  color: #757575;
  font-size: 1.4rem !important;
}

.bold {
  font-family: 'ArimoBold','OpenSans', Arial, Helvetica, sans-serif !important;
}

.ml1 {
  margin-left: 0.8rem;
}

.mr1 {
  margin-right: 0.8rem;
}

.mt1 {
  margin-top: 0.8rem;
}

.mb1 {
  margin-bottom: 0.8rem;
}

/* TOOLTIPS */
body .MuiTooltip-tooltip {
  font-size: 1.2rem;
}

/* BUTTONS */
body .MuiButton-label {
  font-family: 'ArimoBold','OpenSans', Arial, Helvetica, sans-serif;
  text-decoration: none;
}

/* DRAWER */
body .MuiDrawer-paper {
  overflow-y: visible;
}

/* TOGGLE BUTTONS */
body .MuiToggleButton-root {
  min-width: 90px;
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

body .MuiToggleButton-root.Mui-selected {
  color: var(--white);
  background-color: var(--secondary-color);
}

body .MuiToggleButton-root.Mui-selected:hover {
  background-color: var(--secondary-color);
}

body .MuiToggleButton-root svg {
  margin-right: 1rem;
}

body .MuiCard-root {
  overflow: initial;
}

/* MODALS TEMP CSS WHILE WAITING FOR GLOBAL THEME SUPPORT */
body .MuiPickersModal-dialogRoot .MuiDialogActions-root button:first-child {
  color: #007db3;
  background: none;
  box-shadow: none;
}

body .MuiDialogContent-root {
  overflow: initial;
}

/* SNACKBAR */
@media (max-width: 768px) {
  #client-snackbar {
    max-width: 80%;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
