:root {
  /* COLORS */
  --primary-color: #e30713;
  --primary-color-dark: #ab1009;
  --primary-color-light: #fc3842;

  --secondary-color: #007db3;
  --secondary-color-dark: #0b5897;
  --secondary-color-light: #0195d4;
  --secondary-color-lighter: #d7f2fd;

  --tertiary-color: #f1ae0d;

  --success-color: #d0e9d6;
  --success-color-dark: #48a15d;
  --warning-color: #fbe4d5;
  --warning-color-dark: #db8145;
  --error-color: #ffd4d4;
  --error-color-dark: #d33939;
  --info-color: #dbeef6;
  --delete-color: #dc004e;

  --grey-darker: #444444;
  --grey-dark: #737373;
  --grey: #a6a6a6;
  --grey-light: #d9d9d9;
  --grey-lighter: #ececec;

  --black: #1d1d1b;
  --white: #ffffff;

  /* RESPONSIVE WIDTHS */
  --wrapperWidthMax: 1200px;
  --wrapperWidthMin: 320px;

  --smallWrapperWidthMax: 1000px;
  --largeWrapperWidthMax: 1400px;

  --tabletWidth: 768px;

  /* MISC */
  --transitionTime: 0.3s;

  --header-height: 80px;

  --zindexSmall: 10;
  --zindexMedium: 50;
  --zindexBig: 100;
}
