@font-face {
  font-family: 'Arimo';
  src: url('/assets/fonts/Arimo-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ArimoBold';
  src: url('/assets/fonts/Arimo-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ArimoItalic';
  src: url('/assets/fonts/Arimo-Italic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url('/assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Pacifico';
  src: url('/assets/fonts/Pacifico-Regular.ttf') format('truetype');
  font-display: swap;
}